body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTableCell-head {
  min-width: 0.5vw;
  max-width: 2vw;
}

.suiviinputs tr {
  width: 100%;
/*  display: block;*/
  display: inline-flex;
}

.suiviinputs td {
  width: 36%;
}

.suiviinputs td label,
.suiviinputs td div {
  max-width: 98%;
}
.suiviinputs > label {
  
}

.suiviinputs .MuiSelect-selectMenu {
  overflow: visible;
}
.suiviinputs .makeStyles-root-90 {
  padding-top: 0 !important;
}
